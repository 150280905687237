<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant"
            label-for="merchant"
          >
            <v-select
              v-model="filters['merchant_id']"
              :options="merchantOptions"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Merchant"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Merchant Business"
            label-for="merchant_business"
          >
            <v-select
              v-model="filters['merchant_business_id']"
              :options="merchantBussinessOptions"
              :reduce="option => option.id"
              label="business_name"
              placeholder="Select Merchant Business"
            >
              <template v-slot:option="option">
                {{ option.business_name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <b-form-group
            label="Requested Type"
            label-for="request_type"
          >
            <v-select
              v-model="filters['request_type_id']"
              :options="requestTypeOptions"
              :reduce="option => option.id"
              label="type"
              placeholder="Select Request Type"
            >
              <template v-slot:option="option">
                {{ option.type }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="filters['status']"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <!-- input -->
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-end mt-1 mb-1 mb-md-0"
          >
            <div>
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`${downloadReportName} - Page ${page}.xls`"
                :bulkname="`${downloadReportName}.xls`"
                :fetch="fetchRequestsList"
                @refresh="fetchRequestsList"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <b-overlay
        id="overlay-background"
        variant="light"
        opacity="0.30"
        blur="10px"
        rounded="sm"
      >
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          show-empty
          sticky-header="100vh"
          class="hide-vertical-scroll"
        >
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(merchant.name)="data">
            <div>
              {{ data.value || 'N/A' }}
            </div>
          </template>
          <template #cell(merchant_businesses.business_name)="data">
            <div>
              {{ data.value || 'N/A' }}
            </div>
          </template>
          <template #cell(requested_date)="data">
            <div>
              {{ __dateTimeFormatter(data.item.requested_date) || 'N/A' }}
            </div>
          </template>
          <template #cell(request_type.type)="data">
            <div>
              {{ data.value || 'N/A' }}
            </div>
          </template>
          <template #cell(merchant_user)="data">
            <div>
              {{ `${data.item.merchant_user.first_name} ${data.item.merchant_user.last_name}` || 'N/A' }}
            </div>
          </template>
          <template #cell(staff)="data">
            <div>
              {{ data.item.staff ? `${data.item.staff.first_name} ${data.item.staff.last_name}` : 'N/A' }}
            </div>
          </template>
          <template #cell(approve_reject_date)="data">
            <div>
              {{ __dateTimeFormatter(data.item.approve_reject_date) || 'N/A' }}
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.value == 0">
              <b-badge variant="light-warning">
                Pending
              </b-badge>
            </div>
            <div v-else-if="data.value == 1">
              <b-badge variant="light-success">
                Approved
              </b-badge>
            </div>
            <div v-else-if="data.value == 2">
              <b-badge variant="light-danger">
                Rejected
              </b-badge>
            </div>
          </template>
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="10"
                  class="text-body align-middle "
                />
              </template>
              <b-dropdown-item
                v-if="data.item.status === 0"
                @click="viewRequest(data.item.id, 'manage')"
              >
                <feather-icon icon="ToolIcon" />
                Manage
              </b-dropdown-item>
              <b-dropdown-item
                v-can="'show-profile-update-approval'"
                @click="viewRequest(data.item.id, 'view')"
              >
                <feather-icon icon="EyeIcon" />
                View
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row cols="12">
          <b-col
            class="d-flex align-items-center justify-content-sm-start"
            md="3"
          >
            <label class="width-75">Per page</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block width-100"
            />
          </b-col>
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-end"
          >
            <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="(meta.total)? meta.total : 0"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <view-request-modal ref="viewRequestModal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BBadge,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import { avatarText } from '@core/utils/filter'
import { timestampToDateTime } from '@core/utils/timeUtil'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import ViewRequestModal from '@/components/Approvals/ViewRequestModal.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import vSelect from 'vue-select'
import getMerchantList from '../reports/ReturnOrderMerchant/MerchantListDropdown'

const MerchantRepository = RepositoryFactory.get('merchant')
const ApprovalSettingsRepository = RepositoryFactory.get('approvalSettings')

function initialState() {
  return {
    avatarText,
    timestampToDateTime,
    merchantOptions: [],
    merchantBussinessOptions: [],
    requestTypeOptions: [],
    filters: {},
    total: 0,
    meta: {},
    loading: false,
    dataLoading: true,
    sort: '',
    perPage: 20,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    statusOptions: [
      {
        name: 'Approved',
        key: '1',
      },
      {
        name: 'Rejected',
        key: '2',
      },
      {
        name: 'Pending',
        key: '0',
      },
    ],
    fields: [
      {
        key: 'merchant.name', label: 'Merchant', sortable: true, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'merchant_businesses.business_name', label: 'Merchant Business', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'requested_date', label: 'Requested Date', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'request_type.type', label: 'Requested Type', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'merchant_user', label: 'Requested By', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'staff', label: 'Approved/Rejected By', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'approve_reject_date', label: 'Approved/Rejected Date', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'status', label: 'Status', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
      {
        key: 'action', label: 'Action', sortable: false, thClass: 'text-left', tdClass: ' text-left',
      },
    ],
    json_fields: {
      Merchant: {
        field: 'merchant.name',
        callback: value => `"${value}"`,
      },
      'Merchant Business': {
        field: 'merchant_businesses.business_name',
      },
      'Requested Date': {
        field: 'requested_date',
        callback: value => `"${value}"`,
      },
      'Requested Type': {
        field: 'request_type.type',
        callback: value => `"${value}"`,
      },
      'Requested By': {
        field: 'merchant_user',
        callback: value => (value && value.first_name && value.last_name ? `"${value.first_name} ${value.last_name}"` : 'N/A'),
      },
      'Approved/Rejected By': {
        field: 'staff',
        callback: value => (value && value.first_name && value.last_name ? `"${value.first_name} ${value.last_name}"` : 'N/A'),
      },
      'Approved/Rejected Date': {
        field: 'approve_reject_date',
        callback: value => (value ? `"${value}"` : 'N/A'),
      },
      Status: {
        field: 'status',
        // eslint-disable-next-line no-nested-ternary
        callback: value => (value === 0 ? 'pending'
          // eslint-disable-next-line no-nested-ternary
          : value === 1 ? 'approve'
            : value === 2 ? 'reject'
              : ''),
      },
    },
    rows: [],
    downloadReportName: 'Approval-Requests',
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BBadge,
    BDropdown,
    BDropdownItem,
    ViewRequestModal,
    FilterButtons,
    BPagination,
    BFormGroup,
    vSelect,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  watch: {
    options: {
      handler() {
        this.fetchRequestsList()
      },
    },
    page() {
      this.fetchRequestsList()
    },
    perPage() {
      this.fetchRequestsList()
    },
    'filters.merchant_id': function (newMerchantId) {
      if (newMerchantId) {
        this.merchantBusinessList(newMerchantId)
      } else {
        this.merchantBusinessOptions = []
      }
    },
  },
  mounted() {
    this.fetchRequestsList()
    this.merchantList()
    this.requestTypeList()
  },
  methods: {
    async fetchRequestsList() {
      try {
        this.loading = true
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await MerchantRepository.getRequestsList(this.perPage, this.page, this.filterQuery))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
      this.dataLoading = false
    },

    viewRequest(id, actionType) {
      const isManage = actionType === 'manage'
      this.$refs.viewRequestModal.openModal(id, isManage)
    },
    async merchantList() {
      this.merchantOptions = await getMerchantList()
    },
    async merchantBusinessList(merchantId) {
      const { data } = (await MerchantRepository.getMerchantBusinessListDropdown(merchantId)).data
      this.merchantBussinessOptions = data
    },
    async requestTypeList() {
      this.groupId = 2
      const { data } = (await ApprovalSettingsRepository.getProfileUpdateSettingTypes(this.groupId)).data
      this.requestTypeOptions = data
    },
    applyFilters() {
      this.fetchRequestsList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchRequestsList()
      })
    },
    onClickRefresh() {
      this.fetchRequestsList()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchRequestsList()
    },
    changePage(value) {
      this.perPage = value
    },
  },
}
</script>
  <style lang="scss">
  @import '../../@core/scss/vue/libs/vue-select.scss';
  @import '../../@core/scss/vue/libs/vue-flatpicker.scss';
  .dropdown .dropdown-menu {
    max-height: 135px;
    overflow-y: auto;
  }
  </style>
