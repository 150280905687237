<template>
  <b-modal
    id="modal-request-view"
    ref="modal"
    v-model="open"
    hide-footer
    no-close-on-backdrop
    size="lg"
    :title="modalTitle"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        class="my-8"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Merchant Name"
              label-cols-md="4"
              label-for="h-mName"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Merchant Name"
              >
                <b-form-input
                  id="mname"
                  v-model="form.merchant_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Merchant Name"
                  type="text"
                  autocomplete="nope"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Merchant Business Name"
              label-cols-md="4"
              label-for="h-mBName"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Merchant Business Name"
              >
                <b-form-input
                  id="mbnames"
                  v-model="form.merchant_business_name"
                  placeholder="Merchant Business Name"
                  type="text"
                  autocomplete="nope"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Setting Type"
              label-cols-md="4"
              label-for="h-type"
            >
              <ValidationProvider
                #default="{ errors }"
                name="Setting Type"
              >
                <b-form-input
                  id="type"
                  v-model="form.settings_type"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Settings Type"
                  type="text"
                  autocomplete="nope"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
    <b-row class="d-flex justify-content-end">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          show-empty
          :items="items"
          :fields="fields"
        >
          <template #empty>
            <TableDataFetching
              :rows="items"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(field)="data">
            <div class="d-flex align-items-start">
              {{ data.item.field }}
            </div>
          </template>
          <template #cell(previous)="data">
            <div class="d-flex align-items-start">
              {{ data.item.previous }}
            </div>
          </template>
          <template #cell(requested)="data">
            <div class="d-flex align-items-start">
              {{ data.item.requested }}
            </div>
          </template>
          <template #cell(action)>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="10"
                  class="text-body align-middle"
                />
              </template>
              <b-dropdown-item
                @click="navigateToView()"
              >
                <feather-icon icon="EyeIcon" />
                View
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div v-if="isManage">
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-can="'approve-profile-update-approval'"
                variant="success"
                class="mb-2 mr-1"
                @click="approveRequest"
              >
                Approve
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-can="'reject-profile-update-approval'"
                variant="danger"
                class="mb-2"
                @click="rejectRequest"
              >
                Reject
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  BTable,
  BDropdown,
  VBModal,
  BButton,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {
      merchant_name: '',
      merchant_business_name: '',
      settings_type: '',
      field: '',
      previous: '',
      requested: '',
    },
    modalLoading: false,
    loading: false,
    fields: [
      {
        key: 'field', label: 'Field', sortable: false, thClass: ' text-left', tdClass: ' text-left',
      },
      {
        key: 'previous', label: 'Previous', sortable: false, thClass: ' text-left', tdClass: ' text-left',
      },
      {
        key: 'requested', label: 'Requested', sortable: false, thClass: ' text-left', tdClass: ' text-left',
      },
      {
        key: 'action', label: 'Action', sortable: false, thClass: ' text-left', tdClass: ' text-left',
      },
    ],
    items: [],
    dataLoading: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BTable,
    BDropdown,
    BButton,
    BDropdownItem,
  },
  mixins: [SuccessMessage, ErrorMessage],
  props: {
    isManage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return initialState()
  },
  computed: {
    modalTitle() {
      return this.isManage ? 'MANAGE PROFILE UPDATE REQUEST' : 'VIEW PROFILE UPDATE REQUEST'
    },
  },
  methods: {
    async openModal(id, isManage = false) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      this.isManage = isManage
      this.modalLoading = true
      this.fetchRequestDetails()
      this.modalLoading = false
    },
    async fetchRequestDetails() {
      this.dataLoading = true
      try {
        const response = await MerchantRepository.getRequestDetails(this.id)
        const { data } = response.data
        this.form.merchant_name = data.merchant.name
        this.form.merchant_business_name = data.merchant_businesses.business_name
        this.form.settings_type = data.request_type.type
        this.form.previous = data.difference.current_data
        this.form.requested = data.difference.requested_data
        this.merchant_id = data.merchant_id
        this.merchant_business_id = data.merchant_business_id
        if (Array.isArray(data.difference)) {
          this.items = data.difference.map(item => ({
            settings_type: data.request_type.type,
            field: item.field,
            previous: item.current_data,
            requested: item.requested_data,
          }))
        } else {
          this.form.previous = data.difference.current_data
          this.form.requested = data.difference.requested_data
          this.items = [{
            settings_type: data.request_type.type,
            field: data.difference.field,
            previous: data.difference.current_data,
            requested: data.difference.requested_data,
          }]
        }
        this.dataLoading = false
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async approveRequest() {
      this.modalLoading = true
      const payload = {
        request_id: this.id,
        status: 1,
      }
      const res = await MerchantRepository.approveRequestDetails(payload)
      if (res.status === 200) {
        this.$parent.onClickRefresh()
        this.showSuccessMessage('Request Approved Successfully')
      } else {
        this.showErrorMessage('Request Approval Failed')
      }
      this.open = false
      this.modalLoading = false
    },
    async rejectRequest() {
      this.modalLoading = true
      const payload = {
        request_id: this.id,
        status: 2,
      }
      const res = await MerchantRepository.rejectRequestDetails(payload)
      if (res.status === 200) {
        this.$parent.onClickRefresh()
        this.showSuccessMessage('Request Reject Successfully')
      } else {
        this.showErrorMessage('Request Reject Fail')
      }
      this.open = false
      this.modalLoading = false
    },
    async navigateToView() {
      const id = this.merchant_id
      const businessId = this.merchant_business_id
      const route = this.$router.resolve({
        name: 'merchant-business-details',
        params: {
          id,
          businessId,
        },
      })

      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
